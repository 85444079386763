import {jwtDecode} from 'jwt-decode';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const isTokenExpired = (): boolean => {
  const token = localStorage.getItem('token');
  if (!token) return true; // No token = expired

  try {
    const decodedToken: { exp: number } = jwtDecode(token);
    if (!decodedToken.exp) return true; // No expiry field = expired

    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    return decodedToken.exp < currentTime; // Chec
  } catch (error) {
    return true; 
  }
};

const TokenCheck = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isTokenExpired()) {
      localStorage.removeItem('token');
      navigate('/'); // Redirect to login
    }
  }, [navigate]);

  return null;
};

export default TokenCheck;
