const moduleMappings = [
    {
        text: 'Dashboard',
        imageURL: 'sub-categories-icon.svg',
        route: '/dashboard',
        code: 'ADCAT',
    },
    {
        text: 'Categories',
        imageURL: 'categories-icon.svg',
        route: '/categories',
        code: 'ADCAT',
    },
    {
        text: 'Sub-Categories',
        imageURL: 'sub-categories-icon.svg',
        route: '/sub-categories',
        code: 'ADSUBCAT',
    },
    {
        text: 'Score Mapping',
        imageURL: 'score-mapping-icon.svg',
        route: '/score-mapping',
        code: 'ADSM',
    },
    {
        text: 'Slab Mapping',
        imageURL: 'score-mapping-icon.svg',
        route: '/slab-mapping',
        code: 'ADSLM',
    },
    { text: 'KRA', imageURL: 'KRA-icon.svg', route: '/kra', code: 'ADKRA' },
    { text: 'Users', imageURL: 'users-icon.svg', route: '/users', code: 'ADUSR' },
    { text: 'BU', imageURL: 'BU-icon.svg', route: '/bu', code: 'ADBU' },
    { text: 'Designations', imageURL: 'badges-icon.svg', route: '/designations', code: 'ADDSGN' },
    { text: 'Eligibility', imageURL: 'BU-icon.svg', route: '/eligibility', code: 'ADELG' },
    { text: 'Appraisal Allocation', imageURL: 'BU-icon.svg', route: '/appraisal', code: 'ADAPPRAISAL' },
    { text: 'Budget Report', imageURL: 'BU-icon.svg', route: '/budget-report', code: 'ADBUDGETREP' },
    { text: 'Growth Report', imageURL: 'BU-icon.svg', route: '/growthreport', code: 'ADGROWTHREP' },
];

export default moduleMappings;