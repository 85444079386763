import React, { useState } from "react";
import * as XLSX from "xlsx";
import { Button, Modal, Box, Typography, CircularProgress } from "@mui/material";
import { CommHandler } from "../../utils";
import { getAuthHeader } from "../../utils/utils";
import { config } from "../../config";
import { useGrowthContext } from "./GrowthContext";
import { showErrorSnackbar, showSuccessSnackbar } from '../../utils/Snackbar';
const FileUploadModal = () => {
  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [error, setError] = useState("");
  const [fileData, setFileData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { employeeData, setEmployeeData } = useGrowthContext();
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const currentFinancialYear = currentMonth >= 3 ? currentYear : currentYear - 1; 
  
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setError("");
    setSelectedFile(null);
    setFileData([]);
    setIsLoading(false);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    if (file) {
      const fileSizeMB = file.size / (1024 * 1024);
      const fileType = file.name;

      if (!fileType.endsWith(".xls") && !fileType.endsWith(".xlsx") && !fileType.endsWith(".csv")) {
        setError("Only Excel or CSV files are allowed.");
        setSelectedFile(null);
      } else if (fileSizeMB > 25) {
        setError("File size should not exceed 25MB.");
        setSelectedFile(null);
      } else {
        setError("");
        setSelectedFile(file);
      }
    }
  };

  const validateData = (data: any[]) => {
    for (const row of data) {
      for (const key in row) {
        if (!isNaN(Number(key))) {
          if (isNaN(Number(row[key]))) {
            setError(`Invalid data in year ${key}: Expected a number, but found "${row[key]}"`);
            return false;
          }
          if (Number(key) > currentFinancialYear) {
            setError(`Invalid year ${key}: Year should not be greater than ${currentFinancialYear}`);
            return false;
          }
        }
      }
    }
    return true;
  };
  function cleanWorksheetCells(worksheet:any) {
    const range = XLSX.utils.decode_range(worksheet['!ref']);
    for (let R = range.s.r; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
        const cell = worksheet[cellAddress];
        if (cell && cell.v && typeof cell.v === "string") {
          cell.v = cell.v.replace(/\s+/g, " ").trim();
        }
      }
    }
  }

  const handleUpload = () => {
    if (selectedFile) {
      setIsLoading(true);
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target?.result as ArrayBuffer);
            let parsedData: any[] = [];

            if (selectedFile?.name?.endsWith(".csv")) {
                const textDecoder = new TextDecoder("utf-8");
                const csvData = textDecoder.decode(data);
                const rows = csvData.split("\n").map(row => row.split(",").map(val => val.trim()));
                const headers = rows[0];
                parsedData = rows.slice(1).map((row) => {
                    if (row.length < 2) return null;

                    const rowData: { [key: string]: any } = {};
                    rowData["Employee Name"] = row[0];
                    rowData["Employee Code"] = row[1];

                    headers.slice(2).forEach((header, index) => {
                        const value = parseFloat(row[index + 2]) || 0;
                        if (value !== 0) rowData[header] = value;
                    });
                    return rowData;
                }).filter(Boolean);
            } else {
                const workbook = XLSX.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                cleanWorksheetCells(sheet);
                parsedData = XLSX.utils.sheet_to_json(sheet);
            }

            if (validateData(parsedData)) {
                setFileData(parsedData);
                console.log("Extracted Data:", parsedData);
                fetchData(parsedData);
                setIsLoading(false);
                handleClose();
            }
        };
        reader.readAsArrayBuffer(selectedFile);
    }
  };

  async function fetchData(parsedData: any) {
    try {
      const response = await CommHandler.request({
        url: `${config.apiBaseUrl}/growth`,
        method: "POST",
        data: parsedData,
        headers: getAuthHeader,
      });
      if (response.status === 200) {
        if (JSON.stringify(employeeData) !== JSON.stringify(response.data)) {
          setEmployeeData(response?.data);
          showSuccessSnackbar("Growth Report Uploaded Successfully");
        }
      } else {
        console.error("Failed to fetch data:");
      }
    } catch (err) {
      showErrorSnackbar("Failed to upload report");
      console.error("Error fetching data:", err);
    }
  }

  return (
    <div
      style={{
        display: "flex",
        paddingRight: "1rem",
        justifyContent: "flex-end",
      }}
    >
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Upload Growth Data
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            textAlign: "center"
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            Upload an Excel or CSV File
          </Typography>
          <input type="file" accept=".xls,.xlsx,.csv" onChange={handleFileChange} disabled={isLoading} />
          {error && <Typography color="error">{error}</Typography>}
          {isLoading && (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <CircularProgress />
              <Typography variant="body2" sx={{ ml: 2 }}>Processing file...</Typography>
            </Box>
          )}

          <Box mt={2}>
            <Button variant="contained" color="primary" onClick={handleUpload} disabled={!selectedFile || isLoading}>
              {isLoading ? "Uploading..." : "Upload & Extract"}
            </Button>
            <Button onClick={handleClose} sx={{ ml: 2 }} disabled={isLoading}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default FileUploadModal;
