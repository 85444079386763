/* eslint-disable */
import React, { createContext, useContext, ReactNode, Dispatch, SetStateAction } from 'react';

type FinancialYear = {
  year: string;
  value: number;
};

type EmployeeData = {
  id: number;
  employee_name: string;
  employee_code: string;
  financial_year: FinancialYear[];
  createdAt: string;
  updatedAt: string;
};

type GrowthContext = {
  employeeData: EmployeeData[];
  setEmployeeData: Dispatch<SetStateAction<EmployeeData[]>>;
};

const GrowthReportContext = createContext<GrowthContext | undefined>(undefined);

type ArtifactsContextProviderProps = {
  children: ReactNode;
};

const GrowthContextProvider: React.FC<ArtifactsContextProviderProps> = ({ children }) => {
  const [employeeData, setEmployeeData] = React.useState<EmployeeData[]>([]);

  const value: GrowthContext = {
    employeeData,
    setEmployeeData,
  };

  return (
    <GrowthReportContext.Provider value={value}>
      {children}
    </GrowthReportContext.Provider>
  );
};

const useGrowthContext = () => {
  const context = useContext(GrowthReportContext);
  if (!context) {
    throw new Error(
      'GrowthReportContext must be used within an GrowthReportContextProvider'
    );
  }
  return context;
};

export { GrowthContextProvider, useGrowthContext };
